const user = {
    state: {
      adminUserInfo: {},
      roles: [],
      onlyAdminRoles: [],
      onlyStaffRoles: [],
    },
    mutations: {
      SET_adminUserInfo(state, adminUserInfo) {
        console.log('SET_adminUserInfo')
        state.adminUserInfo = adminUserInfo
      },
    },
    actions: {
      // 获取用户信息
      setAdminUserInfo({ commit },adminUserInfo) {
        console.log('setAdminUserInfo')
        commit('SET_adminUserInfo',adminUserInfo)
      },
       
     
    }
}

export default user
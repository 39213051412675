<template>
  <div>
    <div class="folder" v-if="!showPageSwitch">
      <Header-Back pageTitle="New Folder"></Header-Back>

      <FolderForm @tabPage="tabPage" :form='form' ref="FolderForm"></FolderForm>
      

      <div class="">
        <el-button type="primary" @click="save"> Create </el-button> 
      </div>
    </div>

    <EditPage v-else :id="id" @tabPage="tabPage"></EditPage>
  </div>
</template>

<script>
import FolderForm from '../compoments/folderForm.vue'
import EditPage from './editPage.vue'
import { addEpisodes } from '@/api/material'

export default {
  components: {
    FolderForm,
    EditPage
  },
  data() {
    return {
      form: {
        page_id: 0,
        parent_id: 0,
        type: 0,
        title_en: '',
        title_zhHant: '',
        title_zhCn: '',
        thumbnail_en: '',
        thumbnail_zhHant: '',
        thumbnail_zhCn: '',
        description_en: '',
        description_zhHant: '',
        description_zhCn: '',
        sub_pages: [],
        documents: [
          {
            title_en: '',
            title_zhHant: '',
            title_zhCn: '',
            subtitle_en: '',
            subtitle_zhHant: '',
            subtitle_zhCn: '',
            type: 0,
            link_en: '',
            link_zhHant: '',
            link_zhCn: '',
            sorting_order: 0
          }
        ],
        sub_pages_ids: []
      },

      id: null,
      key: null,
      pageId: null,
      showPageSwitch: false
    }
  },
  methods: {
    tabPage(type, data, key = null) {
      switch(type) {
        case 'folder' :
          if(key != null) {
            this.id = data.sub_pages[key].id
            this.key = key
          }
          this.form = data; break
        case 'page' :
          if(this.key != null) {
            this.form.sub_pages[this.key] = data
            this.key = null
            this.id = null
          } else 
          this.form.sub_pages.push(data); break
      }
      
      this.showPageSwitch = !this.showPageSwitch
    },
    save() {
      let child = this.$refs.FolderForm,
      { ruleForm } = child
      , form = {
        "page_id": this.pageId,
        "type": 0,
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn
        },
        "description": {
          "en": ruleForm.description_en,
          "zh_hant": ruleForm.description_zhHant,
          "zh_cn": ruleForm.description_zhCn
        },
        "thumbnail": {
          "en": ruleForm.thumbnail_en,
          "zh_hant": ruleForm.thumbnail_zhHant,
          "zh_cn": ruleForm.thumbnail_zhCn
        },
        "documents": [...ruleForm.documents.map(its => {
          return {
            "title": {
              "en": its.title_en,
              "zh_hant": its.title_zhHant,
              "zh_cn": its.title_zhCn
            },
            "subtitle": {
              "en": its.subtitle_en,
              "zh_hant": its.subtitle_zhHant,
              "zh_cn": its.subtitle_zhCn
            },
            "type": Number(its.type),
            "link": {
              "en": its.link_en,
              "zh_hant": its.link_zhHant,
              "zh_cn": its.link_zhCn
            },
            "sorting_order": its.sorting_order
          }
        })].sort((a, b) => a.sorting_order - b.sorting_order),
        "sub_pages_ids": ruleForm.sub_pages.map(its => its.id),
      }
      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addEpisodes(form).then(() => {
            this.$router.go(-1)
          })
        }
      })
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId
  }
}
</script>

<style lang="scss" scoped>

</style>
import axios from 'axios'
import { toSnakeCase, toCamelCase } from './data-format'
import { getToken,getUserInfo,setToken,removeToken } from '@/utils/auth'
import { Message } from 'element-ui';
import { refresh } from '@/api/security'

const http = axios.create({
  // baseURL: 'https://labour-wsa.dev-a.kanhan.com/api/portal/v1',
  // baseURL: '/api',
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000
})

http.interceptors.request.use(
  config => {
    const token = getToken()
    config.headers.common['Authorization'] = `Bearer ${token}`
    config.headers.common['accept-language'] = `en`
    if (!config.unformat) {
      config.params = toSnakeCase(config.params)
      config.data = toSnakeCase(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  response => {
    if (response.headers['content-type'] === 'application/json') {
      response.data = toCamelCase(response.data)
    }
    return response
  },
  error => {
    if (error.response.status === 401) {
      Message.error(error.response.data.message);
      let d={
        refreshToken:getUserInfo('refreshToken')
      }
      refresh(d).then((v)=>{
        setToken(v.data.accessToken)
      }).catch(()=>{
        removeToken()
      }).finally(()=>{
        location.reload();
      })
    } else if(error.response.status == 400) {
      Message.error(error.response.data);
    } else if(error.response.status == 500) {
      Message.error(error.response.data);
    } else if(error.response.config.url !== '/sessions' && error.response.config.method !== 'delete') {
      Message.error(error.response.data);
    }
    return Promise.reject(error)
  }
)

export default http

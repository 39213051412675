import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    Pages: [], // 内页
    Section: [], // 栏目
    Template: [], // 模版
    TempOSHpage: [], // OSH folder pages
    TempEpisodespage: [] // Episodes folder pages
  },
  mutations: {
    setPages(state, data) {
      state.Pages = data
    },
    setSection(state, data) {
      state.Section = data
    },
    setTemplate(state, data) {
      state.Template = data
    },
    setTempOSHpage(state, data) {
      state.TempOSHpage = data
    },
    setTempEpisodespage(state, data) {
      state.TempEpisodespage = data
    }
  },
  modules: {
    user
  },
  getters,
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})

export default store
<template>
  <div class="">
    <Header-Back pageTitle="New Page"></Header-Back>

    <PageForm :form="form" ref="PageForm"></PageForm>

    <div class="">
      <el-button type="primary" @click="save"> Create </el-button> 
    </div>
  </div>
</template>

<script>
import PageForm from '../compoments/pageForm.vue'
import { addEpisodes } from '@/api/material'

export default {
  components: {
    PageForm
  },
  data() {
    return {
      form: {
        page_id: 0,
        parent_id: 0,
        type: 0,
        title_en: '',
        title_zhHant: '',
        title_zhCn: '',
        thumbnail_en: '',
        thumbnail_zhHant: '',
        thumbnail_zhCn: '',
        description_en: '',
        description_zhHant: '',
        description_zhCn: '',
        documents: [
          {
            title_en: '',
            title_zhHant: '',
            title_zhCn: '',
            subtitle_en: '',
            subtitle_zhHant: '',
            subtitle_zhCn: '',
            type: 0,
            link_en: '',
            link_zhHant: '',
            link_zhCn: '',
            sorting_order: 0
          }
        ],
        sub_pages_ids: []
      }
    }
  },
  methods: {
    save() {
      let child = this.$refs.PageForm,
      { ruleForm } = child,
      form = {
        "page_id": this.pageId,
        "type": 1,
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn
        },
        "description": {
          "en": ruleForm.description_en,
          "zh_hant": ruleForm.description_zhHant,
          "zh_cn": ruleForm.description_zhCn
        },
        "thumbnail": {
          "en": ruleForm.thumbnail_en,
          "zh_hant": ruleForm.thumbnail_zhHant,
          "zh_cn": ruleForm.thumbnail_zhCn
        },
        "documents": [...ruleForm.documents.map(its => {
          return {
            "title": {
              "en": its.title_en,
              "zh_hant": its.title_zhHant,
              "zh_cn": its.title_zhCn
            },
            "subtitle": {
              "en": its.subtitle_en,
              "zh_hant": its.subtitle_zhHant,
              "zh_cn": its.subtitle_zhCn
            },
            "type": Number(its.type),
            "link": {
              "en": its.link_en,
              "zh_hant": its.link_zhHant,
              "zh_cn": its.link_zhCn
            },
            "sorting_order": its.sorting_order
          }
        })],
        "sub_pages_ids": ruleForm.sub_pages_ids
      }

      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addEpisodes(form).then(() => {
            this.$router.go(-1)
          })
        }
      })
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId
  }
}
</script>

<style lang="scss" scoped>

</style>
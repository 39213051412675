<template>
  <div class="">
    <Header-Back pageTitle="New File"></Header-Back>

    <FileForm :form="form" ref="fileForm"></FileForm>

    <div class="">
      <el-button type="primary" @click="save"> Create </el-button> 
    </div>
  </div>
</template>

<script>
import FileForm from '../compoments/fileForm.vue'
import { addEpisodes } from '@/api/material'

export default {
  components: {
    FileForm
  },
  data() {
    return {
      form: {
        page_id: 0,
        parent_id: 0,
        type: 0,
        title_en: '',
        title_zhHant: '',
        title_zhCn: '',
        description_en: '',
        description_zhHant: '', 
        description_zhCn: '',
        thumbnail_en: '',
        thumbnail_zhHant: '',
        thumbnail_zhCn: '',
        doc_type: 0,
        doc_link_en: '',
        doc_link_zhHant: '',
        doc_link_zhCn: '',
      },
      pageId: null
    }
  },
  methods: {
    save() {
      let child = this.$refs.fileForm,
      { ruleForm } = child,
      form = {
        "page_id": this.pageId,
        "type": 2,
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn
        },
        "description": {
          "en": ruleForm.description_en,
          "zh_hant": ruleForm.description_zhHant,
          "zh_cn": ruleForm.description_zhCn
        },
        "thumbnail": {
          "en": ruleForm.thumbnail_en,
          "zh_hant": ruleForm.thumbnail_zhHant,
          "zh_cn": ruleForm.thumbnail_zhCn
        },
        "documents": [
          {
            "title": {
              "en": ruleForm.title_en,
              "zh_hant": ruleForm.title_zhHant,
              "zh_cn": ruleForm.title_zhCn
            },
            "subtitle": {
              "en": ruleForm.description_en,
              "zh_hant": ruleForm.description_zhHant,
              "zh_cn": ruleForm.description_zhCn
            },
            "type": Number(ruleForm.doc_type),
            "link": {
              "en": ruleForm.doc_link_en,
              "zh_hant": ruleForm.doc_link_zhHant,
              "zh_cn": ruleForm.doc_link_zhCn
            },
            "sorting_order": 0
          }
        ],
        "sub_pages_ids": []
      }

      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addEpisodes(form).then(() => {
            this.$router.go(-1)
          })
        }
      })
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId
  }
}
</script>

<style>

</style>
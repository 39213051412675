<template>
  <div class="summary">
    <div class="" v-for="(its, idx) in galleries" :key="idx + 'video'">
      <div class="serial">{{ idx + 1 }}</div>

      <el-form :model="its" label-position="top" :rules="rules" ref="ruleForm" v-if="its.type == 'video'"
       label-width="100px" class="single">
        
        <div class="language">
          <div class="l-single" v-for="(lang, index) in languageList" :key="index">
            <div class="header-title required">{{ lang.title }}</div>
            <div class="left">
              <el-form-item :prop="`image_url_${lang.acronym}`">
                <div class="avatar">
                  <div class="img empty-img" v-if="!its[`image_url_${lang.acronym}`]"></div>
                  <div class="img" v-else >
                    <img :src="its[`image_url_${lang.acronym}`]" alt="">
                  </div>
                  <el-button type="primary" @click="updateFile(its, lang)">Browse</el-button>
                </div>
              </el-form-item>
              
            </div>
            <div class="right">
              <el-form-item :label="`Video Link - ${lang.title}`" :prop="`link_${lang.acronym}`">
                <edit-div v-model="its[`link_${lang.acronym}`]"></edit-div>
              </el-form-item>
              <el-form-item :label="`Transcript - ${lang.title}`" :prop="`transcript_${lang.acronym}`">
                <Tinymce v-model="its[`transcript_${lang.acronym}`]"></Tinymce>
              </el-form-item>
              <el-form-item :label="`Description - ${lang.title}`" :prop="`description_${lang.acronym}`">
                <el-input type="textarea" rows="6" v-model="its[`description_${lang.acronym}`]" autocomplete="off"></el-input>
              </el-form-item>
            </div>
          </div>

        </div>
        <div class="order">
          <img src="@/assets/images/order_up.png" alt="" @click="order('up', idx)">
          <img src="@/assets/images/order_down.png" alt="" @click="order('down', idx)">
        </div>

        <img src="@/assets/images/mingcute_delete-2-fill.png" class="close" @click="close('photo', idx)" alt="">

      </el-form>

      <el-form :model="its" label-position="top" :rules="rules" ref="ruleForm" label-width="100px"
      class="single" v-if="its.type == 'photo'">
        <img src="@/assets/images/mingcute_delete-2-fill.png" class="close" @click="close('photo', idx)" alt="">

        <div class="language">
          <div class="l-single" v-for="(lang, idx) in languageList" :key="idx">
            <div class="header-title required">{{ lang.title }}</div>

            <div class="left">
              <el-form-item :prop="`image_url_${lang.acronym}`">
                <div class="avatar">
                  <div class="img empty-img" v-if="!its[`image_url_${lang.acronym}`]"></div>
                  <div class="img" v-else >
                    <img :src="its[`image_url_${lang.acronym}`]" alt="">
                  </div>
                  <el-button type="primary" @click="updateFile(its, lang)">Browse</el-button>
                </div>
              </el-form-item>
            </div>

            <div class="right">
              <el-form-item :label="`Description - ${lang.title}`" :prop="`description_${lang.acronym}`">
                <el-input type="textarea" rows="4" v-model="its[`description_${lang.acronym}`]" autocomplete="off"></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="order">
          <img src="@/assets/images/order_up.png" alt="" @click="order('up', idx)">
          <img src="@/assets/images/order_down.png" alt="" @click="order('down', idx)">
        </div>
        
      </el-form>
    </div>
    <div class="add">
      <div class="add-btn" @click="addPhoto">
        <img src="@/assets/images/add.png" alt="">
        <span>Add Photo</span>
      </div>

      <div class="add-btn" @click="addVideo">
        <img src="@/assets/images/add.png" alt="">
        <span>Add Video</span>
      </div>
    </div>
  </div>
</template>

<script>
import { updateFile } from '@/utils/upload'

export default {
  props: {
    obj: {
      type: Array,
    }
  },
  data() {
    return {
      languageList: [
        {
          title: 'English',
          acronym: 'en',
        },
        {
          title: 'Traditional Chinese',
          acronym: 'zhHant',
        },
        {
          title: 'Simplified Chinese',
          acronym: 'zhCn',
        }
      ],

      galleries: [],
      rules: {
        'link_en': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
        ],
        'link_zhHant': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
        ],
        'link_zhCn': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
        ],
      }
    }
  },
  methods: {
    close(type, idx){
      //彈窗
      this.$confirm('Confirm to delete?', '',
      {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then(() => {
        this.galleries.splice(idx, 1)
      })
    },
    updateFile(its, lang) {
      let suffix = [], 
      area = { width: 0, height: 0 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        its[`image_url_${lang.acronym}`] = res
      })
    },
    addPhoto() {
      this.galleries.push({
        type: 'photo',
        image_url_en: '',
        image_url_zhHant: '',
        image_url_zhCn: '',
        description_en: '',
        description_zhHant: '',
        description_zhCn: '',
        display_sort: this.galleries.length
      })
      this.verifyLink()

    },
    addVideo() {
      this.galleries.push({
        type: 'video',
        image_url_en: '',
        image_url_zhHant: '',
        image_url_zhCn: '',
        link_en: '',
        link_zhHant: '',
        link_zhCn: '',
        transcript_en: '',
        transcript_zhHant: '',
        transcript_zhCn: '',
        description_en: '',
        description_zhHant: '',
        description_zhCn: '',
        display_sort: this.galleries.length
      })
      this.verifyLink()
    },
    order(type, idx) {
      let { galleries } = this,
      leng = galleries.length

      if(type == 'up' && idx != 0) {
        galleries[idx].display_sort -= 1;
        galleries[idx - 1].display_sort += 1;
        [galleries[idx], galleries[idx - 1]] = [galleries[idx - 1], galleries[idx]];
      } else if(type == 'down' && idx != leng - 1) {
        galleries[idx].display_sort += 1;
        galleries[idx + 1].display_sort -= 1;
        [galleries[idx], galleries[idx + 1]] = [galleries[idx + 1], galleries[idx]];
      }

      this.galleries = JSON.parse(JSON.stringify(galleries))
    },
    verifyLink() {
      let leng = this.galleries.length
      for(let i = 0; i < leng; i++) {
        this.rules[`image_url_en`] = [
          { required: true, message: 'Please select Image', trigger: 'blur'}
        ]
        this.rules[`image_url_zhHant`] = [
          { required: true, message: 'Please select Image', trigger: 'blur'}
        ]
        this.rules[`image_url_zhCn`] = [
          { required: true, message: 'Please select Image', trigger: 'blur'}
        ]
      }

      console.log(this.rules)
    },
  },
  created() {
    this.galleries = JSON.parse(JSON.stringify(this.obj))
    console.log(this.galleries)
    this.verifyLink()
  },
  beforeDestroy() {
    this.$emit('unifiedData', 'summary', this.galleries)
  }
}
</script>

<style lang="scss" scoped>
.summary {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  .serial {
    color: #1A74B0;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin: 10px 0;
  }

  .single {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    position: relative;

    .language {
      width: 100%;
      
      .l-single {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 2px solid #D9D9D9;

        .header-title {
          width: 100%;
          color: black;
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 20px;
        }

        .left {
          width: 280px;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          padding-right: 30px;
          box-sizing: border-box;
  
          .avatar {
            width: 250px;
  
            .img {
              width: 100%;
              height: 145px;
              margin-bottom: 20px;
  
              img {
                width: 100%;
                height: 100%;
              }
            }
  
            .empty-img {
              border: 1px dashed #6D6D6D;
              cursor: pointer;
            }
          }
  
        }
  
        .right {
          width: calc(100% - 320px);
        }
      }
    }

    .close {
      position: absolute;
      bottom: 30px;
      right: 20px;
      width: 30px;
      height: auto;
      cursor: pointer;
    }

    .order {
      width: 280px;
      padding-right: 130px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 48px;
        height: auto;
        cursor: pointer;
      }
    }
  }
}
.add {
  width: 100%;
  margin: 30px 0 40px 0;
  display: flex;

  .add-btn {
    padding: 0 20px;
    height: 44px;
    margin-right: 20px;
    border: 1px solid #1A74B0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer; 

    img {
      width: 24px;
      height: auto;
      margin-right: 10px;
    }
  }
}

.required {
  padding-left: 10px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '*';
    color: #F56C6C;
    font-size: 14px;
  }
}
</style>
<template>
  <div>
    <div class="" v-if="!showPageSwitch">
      <Header-Back :pageTitle="form.title_en"></Header-Back>

      <FolderForm @tabPage="tabPage" v-if="form.id" :form="form" ref="FolderForm"></FolderForm>

      <div class="">
        <el-button type="primary" @click="save"> Save </el-button> 
        <el-button type="danger" @click="del"> <img src="@/assets/images/delete-save.png" alt=""> Delete </el-button> 
      </div>
    </div>

    <EditPage v-else :id="id" @tabPage="tabPage"></EditPage>
  </div>
</template>

<script>
import FolderForm from '../compoments/folderForm.vue'
import EditPage from './editPage.vue'
import { editEpisodes, getEpisodesDetail, deleteEpisodes } from '@/api/material'
import { mapMutations } from 'vuex'

export default {
  components: {
    FolderForm,
    EditPage
  },
  data() {
    return {
      form: {
        page_id: 0,
        parent_id: 0,
        type: 0,
        title_en: '',
        title_zhHant: '',
        title_zhCn: '',
        thumbnail_en: '',
        thumbnail_zhHant: '',
        thumbnail_zhCn: '',
        description_en: '',
        description_zhHant: '',
        description_zhCn: '',
        sub_pages_ids: []
      },

      id: null,
      key: null,
      pageId: null,
      showPageSwitch: false
    }
  },
  methods: {
    ...mapMutations(['setTempEpisodespage']),
    del() {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }
      ).then(() => {
        //刪除
        let id = this.$route.params.id
        deleteEpisodes(id).then(()=>{
          this.$message({
            type: 'success',
            message: 'Successfully deleted!'
          })
          this.$router.go(-1)
        })
      })
    },
    tabPage(type, data, key = null) {
      switch(type) {
        case 'folder' :
          if(key != null) {
            this.id = data.sub_pages[key].id
            this.key = key
          }
          this.form = data; break
        case 'page' :
          if(this.key != null) {
            this.form.sub_pages[this.key] = data
            this.key = null
            this.id = null
          } else 
          this.form.sub_pages.push(data); break
      }
      
      this.showPageSwitch = !this.showPageSwitch
    },
    save() {
      let child = this.$refs.FolderForm,
      { ruleForm } = child
      , form = {
        "type": 0,
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn
        },
        "description": {
          "en": ruleForm.description_en,
          "zh_hant": ruleForm.description_zhHant,
          "zh_cn": ruleForm.description_zhCn
        },
        "thumbnail": {
          "en": ruleForm.thumbnail_en,
          "zh_hant": ruleForm.thumbnail_zhHant,
          "zh_cn": ruleForm.thumbnail_zhCn
        },
        "documents": [...ruleForm.documents.map(its => {
          return {
            "title": {
              "en": its.title_en,
              "zh_hant": its.title_zhHant,
              "zh_cn": its.title_zhCn
            },
            "subtitle": {
              "en": its.subtitle_en,
              "zh_hant": its.subtitle_zhHant,
              "zh_cn": its.subtitle_zhCn
            },
            "type": Number(its.type),
            "link": {
              "en": its.link_en,
              "zh_hant": its.link_zhHant,
              "zh_cn": its.link_zhCn
            },
            "sorting_order": its.sorting_order
          }
        })].sort((a, b) => a.sorting_order - b.sorting_order),
        "sub_pages_ids": ruleForm.sub_pages.map(its => its.id)
      }

      child.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          editEpisodes(ruleForm.id, form).then(() => {
            this.$message.success('modify successfully!')
            this.$router.go(-1)
          })
        }
      })
    },
    getData() {
      let id = this.$route.params.id
      getEpisodesDetail(id).then(v => {
        this.form = {
          id: v.data.id,
          title_en: v.data.title.en,
          title_zhHant: v.data.title.zhHant,
          title_zhCn: v.data.title.zhCn,
          description_en: v.data.description.en,
          description_zhHant: v.data.description.zhHant,
          description_zhCn: v.data.description.zhCn,
          thumbnail_en: v.data.thumbnail.en,
          thumbnail_zhHant: v.data.thumbnail.zhHant,
          thumbnail_zhCn: v.data.thumbnail.zhCn,
          sub_pages: v.data.subPages,
          documents: [...v.data.documents.map(its => {
            return {
              title_en: its.title.en,
              title_zhHant: its.title.zhHant,
              title_zhCn: its.title.zhCn,
              subtitle_en: its.subtitle.en,
              subtitle_zhHant: its.subtitle.zhHant,
              subtitle_zhCn: its.subtitle.zhCn,
              type: its.type,
              link_en: its.link.en,
              link_zhHant: its.link.zhHant,
              link_zhCn: its.link.zhCn,
              sorting_order: its.sortingOrder
            }
          })],
        }
        this.setTempEpisodespage(v.data.subPages)
      })
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId

    this.getData()
  }
}
</script>

<style lang="scss" scoped>

</style>
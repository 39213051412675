<template>
  <div class="">
    <el-form :model="ruleForm" label-position="top" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="line">Title</div>
      <el-form-item label="English" prop="title_en">
        <el-input v-model="ruleForm.title_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="title_zhHant">
        <el-input v-model="ruleForm.title_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="title_zhCn">
        <el-input v-model="ruleForm.title_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <div class="line">Thumbnail</div>
      <el-form-item prop="thumbnail_en" label="English">
        <div class="logo">
          <img :src="ruleForm.thumbnail_en" class="image" v-if="ruleForm.thumbnail_en" alt="">
          <div class="image" v-else></div>
          <div class="logo-info">
            <span>Type: PNG, JPG or BMP</span>
            <span>Maximum file size:  5MB</span>
            <span>Dimension: at least 282px(H) x 504px(W)</span>
            <el-button type="primary" @click="isUpdateFile('thumbnail_en')">Browse</el-button>
            <el-button type="danger" @click="removeThumbnail('thumbnail_en')">Delete</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="thumbnail_zhHant" label="Traditional Chinese">
        <div class="logo">
          <img :src="ruleForm.thumbnail_zhHant" class="image" v-if="ruleForm.thumbnail_zhHant" alt="">
          <div class="image" v-else></div>
          <div class="logo-info">
            <span>Type: PNG, JPG or BMP</span>
            <span>Maximum file size:  5MB</span>
            <span>Dimension: at least 282px(H) x 504px(W)</span>
            <el-button type="primary" @click="isUpdateFile('thumbnail_zhHant')">Browse</el-button>
            <el-button type="danger" @click="removeThumbnail('thumbnail_zhHant')">Delete</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="thumbnail_zhCn" label="Simplified Chinese">
        <div class="logo">
          <img :src="ruleForm.thumbnail_zhCn" class="image" v-if="ruleForm.thumbnail_zhCn" alt="">
          <div class="image" v-else></div>
          <div class="logo-info">
            <span>Type: PNG, JPG or BMP</span>
            <span>Maximum file size:  5MB</span>
            <span>Dimension: at least 282px(H) x 504px(W)</span>
            <el-button type="primary" @click="isUpdateFile('thumbnail_zhCn')">Browse</el-button>
            <el-button type="danger" @click="removeThumbnail('thumbnail_zhCn')">Delete</el-button>
          </div>
        </div>
      </el-form-item>

      <div class="line">Subtitle</div>
      <el-form-item label="English">
        <el-input v-model="ruleForm.description_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <el-input v-model="ruleForm.description_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <el-input v-model="ruleForm.description_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item>
        <el-radio-group v-model="ruleForm.doc_type">
          <el-radio :label="0">PDF</el-radio>
          <el-radio :label="1">External Link</el-radio>
          <el-radio :label="2">Photo</el-radio>
          <el-radio :label="3">Video</el-radio>
        </el-radio-group>
      </el-form-item>

      <div class="group">
        <el-form-item label="Link - English" prop="doc_link_en">
          <edit-div v-model="ruleForm.doc_link_en"></edit-div>
        </el-form-item>
        <el-form-item label="Link - Traditional Chinese" prop="doc_link_zhHant">
          <edit-div v-model="ruleForm.doc_link_zhHant"></edit-div>
        </el-form-item>
        <el-form-item label="Link - Simplified Chinese" prop="doc_link_zhCn">
          <edit-div v-model="ruleForm.doc_link_zhCn"></edit-div>
        </el-form-item>
      </div>

    </el-form>
  
  </div>
</template>

<script>
import { updateFile } from '@/utils/upload'

export default {
  props: {
    form: {
      type: Object
    }
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        title_en: [
          { required: true, message: 'Title English can not be empty', trigger: 'blur'}
        ],
        title_zhHant: [
          { required: true, message: 'Title Traditional Chinese can not be empty', trigger: 'blur'}
        ],
        title_zhCn: [
          { required: true, message: 'Title Simplified Chinese can not be empty', trigger: 'blur'}
        ],
        // thumbnail_en: [
        //   { required: true, message: 'Please upload thumbnails in English'}
        // ],
        // thumbnail_zhHant: [
        //   { required: true, message: 'Please upload thumbnails in Traditional Chinese'}
        // ],
        // thumbnail_zhCn: [
        //   { required: true, message: 'Please upload thumbnails in Simplified Chinese'}
        // ],
        'doc_link_en': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
        ],
        'doc_link_zhHant': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
        ],
        'doc_link_zhCn': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
        ],
      }
    }
  },
  methods: {
    isUpdateFile(label) {
      let suffix = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/svg+xml'], 
      area = { width: 504, height: 282 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        this.ruleForm[label] = res
        console.log(this.ruleForm)
      })
    },
    removeThumbnail(label) {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.ruleForm[label] = ''
      })
    },
  },
  mounted() {
    this.ruleForm = this.form ? JSON.parse(JSON.stringify(this.form)) : this.ruleForm
    this.ruleForm.documents && this.verifyLink()
  }
}
</script>

<style lang="scss" scoped>
.doc {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #F5F5F5;
  margin-bottom: 10px;
  position: relative;

  .close {
    width: 28px;
    height: auto;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.group {
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > * {
    width: 32%;
  }
}

.add {
  width: 100%;
  margin: 30px 0 40px 0;

  .add-btn {
    width: 120px;
    height: 44px;
    border: 1px solid #1A74B0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    img {
      width: 24px;
      height: auto;
      margin-right: 10px;
    }
  }
}

.logo {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;

  .image {
    width: 284px;
    height: 188px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;
    .icon {
      width: 2.5rem;
      height: auto;
      transform: rotateZ(45deg);
    }
  }

  .logo-info {
    flex: 1;
    padding-left: 1.25rem;
    color: #000;
    font-size: 1.125rem;
    line-height: 1.25rem;

    span {
      display: block;
      margin-bottom: .625rem;
    }
  }
}
</style>
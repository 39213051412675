<template>
  <div>
    <Header-Back pageTitle="Position Control"></Header-Back>

    <div class="single" v-for="(item, index) in list" :key="index">
      <!-- {{ item.sort }}, {{ item.sortLocal }} -->
      <!-- 标题 -->
      <div class="s-title" v-if="item.sTitle">{{ item.sTitle }}</div>

      <div class="position disable" v-if="item.empty"></div>

      <div :class="['position', item.status == 1 && 'disable', item.sortLocal <= 4 && 'fist-section']" v-if="!item.empty && item.id">
        <div class="position-left">
          <img src="@/assets/images/drag_icon.png" draggable="false" class="drag-icon" @mousedown="downDrag($event, item, index)" alt="">
          <i class="el-icon-arrow-down" v-if="!item.isOpen" @click="item.isOpen = !item.isOpen"></i>
          <i class="el-icon-arrow-up" v-else @click="item.isOpen = !item.isOpen"></i>

          <img :src="item.iconUrl" class="main-icon" v-if="item.iconUrl">
          <span class="type-name">{{ item.title.en }}</span>
        </div>

        <div class="delete-btn" @click="deleteSection(item, index)">Delete</div>
      </div>

      <div class="single-box" v-if="item.isOpen">
        <div class="left">
          <el-row type="flex" align="center" :gutter="20" class="page-list-row">
            <el-col :span="9" class="page-list-label">Icon</el-col>
            <el-col>
              <div class="page-list-img">
                <img :src="item.iconUrl" v-if="item.iconUrl" class="img" alt="" />
                <div class="img" v-else></div>
                <img @click="update(item, 'iconUrl')" class="page-list-img-edit" src="@/assets/images/edit.png">
              </div>
            </el-col>
          </el-row>
  
          <el-row type="flex" align="center" :gutter="20" class="page-list-row">
            <el-col :span="9" class="page-list-label">Arrow</el-col>
            <el-col >
              <el-col :span="8" style="padding-left: 0">
                <div class="page-list-img">
                  <img :src="item.arrowUrl" v-if="item.arrowUrl" class="img arrowUrl" alt="" />
                  <div class="img" v-else></div>
                  <img @click="updateArrow(item, 'arrowUrl')" class="page-list-img-edit" src="@/assets/images/edit.png">
                </div>
              </el-col>
              <el-col :span="5" style="margin-top: 60px;">
                <el-button type="danger" v-if="item.arrowUrl" @click="removeArrow(item, 'arrowUrl')" size="small">Delete</el-button>
              </el-col>
            </el-col>
            
          </el-row>
          
        </div>
        <div class="center">
          <el-row type="flex" align="center" :gutter="20" class="page-list-row">
            <el-col :span="9" class="page-list-label">Title - English</el-col>
            <el-col><el-input v-model="item.title.en" placeholder="Title - English"></el-input
            ></el-col>
          </el-row>
          <el-row type="flex" align="center" :gutter="20" class="page-list-row">
            <el-col :span="9" class="page-list-label">Title - Traditional Chinese </el-col>
            <el-col><el-input v-model="item.title.zhHant" placeholder="Title - Traditional Chinese"></el-input
            ></el-col>
          </el-row>
          <el-row type="flex" align="center" :gutter="20" class="page-list-row">
            <el-col :span="9" class="page-list-label">Title - Simplified Chinese</el-col>
            <el-col><el-input v-model="item.title.zhCn" placeholder="Title - Simplified Chinese"></el-input
            ></el-col>
          </el-row>
          <el-row type="flex" align="top" class="page-list-row" :gutter="20">
            <el-col :span="18" class="page-list-label">Navigate to</el-col>
            <el-col>
              <el-select v-model="item.pages" multiple @change="v => changeTemplate(item, v)"
               :disabled="item.type == 'link'" placeholder="- Internal page -" style="width: 100%">
                <el-option :disabled="its.disable"
                  v-for="(its) in item.tempPages" :key="its.id"
                  :label="its.path" :value="its.id">
                </el-option> 
              </el-select>
              <el-input v-model="item.en" :disabled="item.pages.length != 0" class="select" placeholder="Link - English"></el-input>
              <el-input v-model="item.zhHant" :disabled="item.pages.length != 0" class="select" placeholder="Link - Traditional Chinese"></el-input>
              <el-input v-model="item.zhCn" :disabled="item.pages.length != 0" class="select" placeholder="Link - Simplified Chinese"></el-input>
            </el-col>
            <el-col></el-col>
          </el-row>
          
          <el-row type="flex" align="top" :gutter="20" class="page-list-row" v-if="item.backShow">
            <el-col :span="9" class="page-list-label">Background Image</el-col>
            <el-col>
              <div class="bgimg">
                <div class="img">
                  <img :src="item.backgroundUrl" v-if="item.backgroundUrl" alt="">
                  <img :src="item.defaultBgimg" v-else alt="">
                </div>
                <div class="logo-info">
                  <span>Type: PNG, JPG </span>
                  <span>Maximum file size:  5MB</span>
                  <span>Dimension: at least 200px(H) x 112px(W)</span>
                  <el-button type="primary" @click="isUpdateFile(item)">Browse</el-button>
                </div>
  
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="20">
            <el-col :span="9" class="page-list-label"></el-col>
            <el-col>
              <el-button type="primary" @click="isDisable(item)" v-if="item.status == 1">Enable</el-button>
              <el-button type="info" @click="isDisable(item)" v-else>Disable</el-button>
              <el-button type="danger" @click="deleteSection(item, index)">Delete</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- <div class="right page-list-button">
        <div class="order">
          <img src="@/assets/images/order_up.png" alt="" @click="order('up', index)">
          <img src="@/assets/images/order_down.png" alt="" @click="order('down', index)">
        </div>
      </div> -->
    </div>

    <div @click.self="openAddBtn" class="btn-default"> 
      <img src="@/assets/images/add.png" alt=""> New 
      <div class="add-child" tabindex="1" @blur="add_switch = false" v-if="add_switch">
        <span @click="$router.push('/overview/secondRowSection/addNewSection')">New Section</span>
        <span @click="addLink">External Link</span>
      </div>
    </div>

    <el-button type="primary" @click="save">Save</el-button> 
  </div>
</template>

<script>
import { deleteSections, disableSections, enableSections, editSections } from '@/api/section.js'
import { updateFile } from '@/utils/upload'
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      list: [],

      add_switch: false,
    }
  },
  computed: {
    ...mapState(['Pages', 'Section', 'user', 'Template'])
  },
  watch: {
    Section: function() {
      this.getData()
    }
  },
  methods: {
    ...mapMutations(['setSection']),
    // 拖拽
    downDrag(e, item, idx) {
      console.log(e, item)
      let positions = document.querySelectorAll('.position')
      , pos = positions[idx]
      , {pageX, pageY} = e
      , recordArea = []
      , sign = -1
      , isUse = false

      for(let i = 0; i < positions.length; i++) {
        let node = positions[i]
        let {left, top} = this.getNodeOffset(node)
        , width = node.offsetWidth
        , height = node.offsetHeight
        recordArea.push({
          left, top, width, height
        })
      }
      
      console.log(pageX, pageY, pos, recordArea)
      // position.style = `
      //   position: absolute;
      //   top: ${pageY}px;
      //   left: ${pageX}px;
      // `
      // document.body.append(position)
      document.body.onmousemove = ev => {
        isUse = true
        console.clear()
        let curIdx = -1

        for(let i = 0; i < recordArea.length; i++) {
          let item = recordArea[i]
          if(
            item.left <= ev.pageX && (item.left + item.width) >= ev.pageX
            && item.top <= ev.pageY && (item.top + item.height) >= ev.pageY
          ) {
            curIdx = i
            break
          }
        }

        if(curIdx == -1 && sign != -1) {
          // console.log('不执行区域')
        } else if(curIdx != sign) {
          // 移除上一个空数据
          console.log(sign)
          if(this.list[sign == -1 ? this.list.length - 1 : sign].empty) {
            this.list.splice(sign, 1)
          }
          console.log('-----------------')
          this.list.splice(curIdx, 0, {empty: true})
          sign = curIdx
          console.log(sign)
        }
      }

      document.body.onmouseup = () => {
        document.body.onmousemove = null
        document.body.onmouseup = null
        if(!isUse) return ;
        if(idx < sign) {
          sign = idx < sign ? sign - 1 : sign
          let [cur] = this.list.splice(idx, 1)
          this.list.splice(sign + 1, 0, cur)
          this.list.splice(sign, 1)

          cur.sort = this.list[sign - 1].sort
          cur.sortLocal = this.list[sign - 1 ].sortLocal
          this.list.slice(idx, idx + Math.abs(idx - sign))
            .filter(its => {
              its.sort -= 1
              its.sortLocal -= 1
            })
        } else {
          let newIdx = sign <= idx ? idx + 1 : idx
          let [cur] = this.list.splice(newIdx, 1)
          this.list.splice(sign, 1, cur)
          cur.sort = this.list[sign + 1].sort
          cur.sortLocal = this.list[sign + 1].sortLocal
          this.list.slice(sign + 1, sign + 1 + Math.abs(idx - sign))
            .filter(its => {
              its.sort += 1
              its.sortLocal += 1
            })
        }
        // position.parentNode.removeChild(position)

        // 交换sort
        let disable = [], enable = [], first = []
        , data = this.list
        data = data.filter(its => its.id && its)
        data.filter((its, idx) => {
          its.sTitle = null
          its.isOpen = false
          if(idx < 4) {
            first.push(its)
          } else {
            if(its.status == 0) {
              enable.push(its)
            } else disable.push(its)
          }
        })
        if(first.length == 0) first[0] = {sTitle: 'First Row Sections', sort: 1}
        else first[0].sTitle = 'First Row Sections'

        if(enable.length == 0) enable[0] = {sTitle: 'Second Row Sections', sort: first.length + 1}
        else enable[0].sTitle = 'Second Row Sections'

        if(disable.length == 0) disable[0] = {sTitle: 'Disable Sections', sort: first.length + enable.length + 1}
        else disable[0].sTitle = 'Disable Sections'
        this.list = [
          ...first,
          ...enable,
          ...disable
        ].filter((its, idx) => {
          its.sortLocal = idx + 1
          return its
        })
      }
    },
    ifSectionType(item) {
      if(item.templateType == undefined) return false

      let oshId, nywId, epiId
      this.Template.filter(its => {
        if(its.title == 'Osh Training') oshId = its.type
        else if(its.title == 'Notify Your Workplace-Text Version') nywId = its.type
        else if(its.title == 'Episodes') epiId = its.type
      })
      
      if(item.templateType == oshId) {
        return 'osh';
      } else if(item.templateType == nywId) {
        return 'nyw';
      } else if(item.templateType == epiId) {
        return 'epi';
      }
      
      return false
    },
    getTemplatetype(templates) {
      let [ type ] = templates

      switch(templates.length) {
        case 0: 
          return ''
        case 1: 
          return type.title
        case 2: 
          return 'Work Safety Alert'
      }
    },
    isUpdateFile(item) {
      let suffix = ['image/png', 'image/jpg', 'image/jpeg'], 
      area = { width: 112, height: 200 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        item.backgroundUrl = res
      })
    },
    update(item, label) {
      let suffix = [], 
      area = { width: 240, height: 240 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        item[label] = res
      })
    },
    updateArrow(item, label) {
      let suffix = [], 
      area = { width: 50, height: 50 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        item[label] = res
      })
    },
    removeArrow(item, label) {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        item[label] = ''
      })
    },
    updateTempPages(testData) {
      let pages = JSON.parse(JSON.stringify(this.Pages)).map(its => ({...its, disable: false})), 
      [id] = testData

      if(id == undefined) return pages
      let [filterChoice] = pages.filter(its => its.id == id && its)
      if(filterChoice != undefined && filterChoice.type == 0) {
        pages.map(its => {
          if(its.id != id && its.id != (id + 1))
            its.disable = true
        })
      } else if(filterChoice != undefined && filterChoice.type == 1) {
        pages.map(its => {
          if(its.id != id && its.id != (id - 1))
            its.disable = true
        })
      } else {
        pages.map(its => its.id != id && (its.disable = true))
      }
      
      return pages
    },
    changeTemplate(its, v) {
      its.tempPages = this.updateTempPages(v)
      if(its.pages.length == 0) {
        its.backShow = false
        return 
      }
      its.templateType = this.Pages.filter(item => item.id == its.pages[0] && item)[0].type

      if(this.ifSectionType(its) == 'osh') {
        its.backShow = true
        its.defaultBgimg = require('../../../assets/images/section_osh_bg.jpg')
      } else if(this.ifSectionType(its) == 'epi') {
        its.backShow = true
        // 暂用osh的图片
        its.defaultBgimg = require('../../../assets/images/section_osh_bg.jpg')
      } else if(this.ifSectionType(its) == 'nyw') {
        its.backShow = true
        its.defaultBgimg = require('../../../assets/images/section_nyw_bg.jpg')
      }
    },
    openAddBtn() {
      this.add_switch = true
      this.$nextTick(() => {
        let addBtn = document.querySelector('.add-child')
        addBtn.focus()
      })
    },
    deleteSection(its, idx) {
      let id = its.id
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        if(id == '0') {
          this.list.splice(idx, 1)
        } else {
          deleteSections(id).then(() => {
            this.list.splice(idx, 1)
            this.getSection()
          })
        }
      })
    },
    isDisable(its) {
      let {status, id} = its

      if(id == '0') {
        its.status = status == 0 ? 1 : 0
      } else {
        if(status == 0) {
          disableSections(id).then(() => {
            this.getSection()
          })
        } else {
          enableSections(id).then(() => {
            this.getSection()
          })
        }
      }
    },
    save() {
      let obj = this.list,
      newArr = []
      obj.filter((its, idx) => {
        newArr.push({
          id: its.id,
          display_type: idx < 4 ? 0 : 1,
          "icon_url": its.iconUrl,
          "arrow_url": its.arrowUrl,
          "title": {
            "en": its.title.en,
            "zh_hant": its.title.zhHant,
            "zh_cn": its.title.zhCn
          },
          "page_ids": its.pages,
          "link": {
            "en": its.en,
            "zh_hant": its.zhHant,
            "zh_cn": its.zhCn
          },
          background_url: this.ifSectionType(its) ? its.backgroundUrl : '',
          sort: idx + 1,
          status: its.status
        })
      })
      editSections(newArr).then(() => {
        this.getSection()
        // 更新管理员权限
        this.getAdminRole()
        this.$router.go(-1)
      })
    },
    addLink() {
      console.log(this.list)
      this.list.push({ 
        id: '0', 
        title: {en: '', zhHant: '', zhCn: ''},
        iconUrl: '',
        "pages": [],
        type: 'link',
        en: '',
        zhHant: '',
        zhCn: '',
        sort: 999,
        sortLocal: this.list.length + 1,
        "status": 1,
        isOpen: true,
        templates: []
      })
    },

    order(type, idx) {
      let { list } = this,
      leng = list.length
      
      if(type == 'up' && idx != 0) {
        [list[idx], list[idx - 1]] = [list[idx - 1], list[idx]];
      } else if(type == 'down' && idx != leng - 1) {
        [list[idx], list[idx + 1]] = [list[idx + 1], list[idx]];
      }

      this.list = JSON.parse(JSON.stringify(list))
    },
    getData() {
      let data = JSON.parse(JSON.stringify(this.Section))
      data.filter(its => {
        its.tempPages = []
        if(its.pages.length > 0){
          its.templateType = its.pages.map(i => i)[0].type
          its.pages = its.pages.map(i => i.id)
          its.type = 'section'
          its.tempPages = this.updateTempPages(its.pages)
        }
        else its.type = 'link'
        its.en = its.link.en
        its.zhHant = its.link.zhHant
        its.zhCn = its.link.zhCn

        if(this.ifSectionType(its) == 'osh') {
          its.backShow = true
          its.defaultBgimg = require('../../../assets/images/section_osh_bg.jpg')
        } else if(this.ifSectionType(its) == 'epi') {
          its.backShow = true
          its.defaultBgimg = require('../../../assets/images/section_osh_bg.jpg')
        } else if(this.ifSectionType(its) == 'nyw') {
          its.backShow = true
          its.defaultBgimg = require('../../../assets/images/section_nyw_bg.jpg')
        }
      })
      
      this.handleData(data)
    },
    handleData(data) {
      let disable = [], enable = [], first = []

      data = data.filter((its) => {
        its.sTitle = null
        its.isOpen = false
        if(its.displayType == 1) {
          its.sort += 4
        } else {
          first.push(its)
        }
        return its
      }).sort((a, b) => a.sort - b.sort)
      console.log(data)
      data.filter((its, idx) => {
        if(idx < (4 - first.length)) {
          first.push(its)
        } else if(its.displayType == 1) {
          if(its.status == 0) {
            enable.push(its)
          } else disable.push(its)
        }
      })
      // enable = [enable[0]]
      if(first.length == 0) first[0] = {sTitle: 'First Row Sections', sort: 1}
      else first[0].sTitle = 'First Row Sections'

      if(enable.length == 0) enable[0] = {sTitle: 'Second Row Sections', sort: first.length + 1}
      else enable[0].sTitle = 'Second Row Sections'

      if(disable.length == 0) disable[0] = {sTitle: 'Disable Sections', sort: first.length + enable.length + 1}
      else disable[0].sTitle = 'Disable Sections'
      this.list = [
        ...first,
        ...enable,
        ...disable
      ].filter((its, idx) => {
        its.sortLocal = idx + 1
        return its
      })
    },
    /**
   * 获取dom在文档中的位置坐标
   * @param {node} e 
   * @returns {object<{left: Number, top: Number}>}
   */
    getNodeOffset(e) {
      function getTop(ev){
        let offset = ev.offsetTop;
        if(ev.offsetParent != null){
          offset += getTop(ev.offsetParent);
        }         
        return offset;
      }
    
      function getLeft(ev){
        let offset = ev.offsetLeft;
        if(ev.offsetParent != null){
          offset += getLeft(ev.offsetParent);
        }         
        return offset;
      }
      
      return {left: getLeft(e), top: getTop(e)}
    },
    /**
     * 查询 plate 存在滚动条的所有父节点
     * @param {node} element (default: document.body) 默认以整个文档为范围进行查询
     */
    findscroller(element = document.body) {
      let that = this
      element.onscroll = () => {
        // 过滤
        if(element.contains(that.plate)){
          let {top, left} = that.getNodeOffset(that.plate)
          that.top = top - element.scrollTop
          that.left = left - element.scrollLeft
        }
      }
      Array.from(element.children).forEach(its => that.findscroller(its))
    }
  },
  mounted() {
    this.getData()
  }
};
</script>

<style lang="scss" scoped>
.single {
  width: 100%;
  margin-bottom: 20px;

  .s-title {
    width: 100%;
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }

  .position {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px 5px 5px;
    box-sizing: border-box;
    background-color: #d7e8f5;
    cursor: pointer;

    .position-left {
      display: flex;
      align-items: center;

      .drag-icon {
        width: 45px;
        height: 100%;
        object-fit: contain;
        margin-right: 20px;
        user-select: none;
      }

      i {
        font-size: 33px;
        font-weight: 700;
        margin-right: 20px;
      }

      .main-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 20px;
      }

      .type-name {
        font-size: 20px;
      }
    }

    .delete-btn {
      width: 80px;
      height: 40px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 700;
      background-color: #d93722;
    }
  }

  .single-box {
    width: 100%;
    background-color: #f3f3f3;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 100px;
    box-sizing: border-box;
  }

  .left {
    width: 100%;
    height: 100%;
  }

  .center {
    width: 100%;
    height: 100%;
  }

  .right {
    width: 100%;
    height: 100%;
    display: flex;

    .order {
      width: 100%;
      display: flex;
      justify-content: space-around;

      img {
        width: 48px;
        height: auto;
      }
    }
  }

}

.single .fist-section {
  background-color: #fff0c8;
}

.single .disable {
  background-color: #d4d4d4 !important;
}

.btn-default {
  position: relative;
  width: 122px;
  height: 44px;
  padding: 5px 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  border: 1px solid #1A74B0;
  cursor: pointer;
  margin-bottom: 40px;

  img {
    width: auto;
    height: 20px;
    margin-right: 5px;
    vertical-align: sub;
  }

  .add-child {
    position: absolute;
    top: 0;
    left: calc(100% + 20px);
    padding: 20px;
    box-sizing: border-box;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    z-index: 999;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 44px;
      background-color: #1A74B0;
      color: #FFF;
      font-size: 18px;
      font-weight: 700;
    }

    span:nth-of-type(1) {
      margin-bottom: 20px;
    }
  }
}

.page-list {
  padding: 20px;
  background: #f5f5f5;
  margin-bottom: 14px;
}
.page-list-img {
  position: relative;
  display: flex;
  width: 100px;
  height: 100px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #ebebeb; 

  .img {
    width: 100%;
    height: 100%;
  }

  .arrowUrl {
    width: 35px;
    height: auto;
  }
}
.page-list-button {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.page-list-content {
  justify-content: center;
  flex-direction: column;
  display: flex;
}
.page-list-label {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  align-items: center;
  display: flex;

}
.bgimg {
  width: 100%;
  display: flex;
  align-items: center;

  .img {
    width: 112px;
    height: 200px;
    border: 1px solid #6D6D6D;
    background-size: 100% 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .logo-info {
    flex: 1;
    padding-left: 1.25rem;
    color: #000;
    font-size: 1.125rem;
    line-height: 1.25rem;

    span {
      display: block;
      margin-bottom: .625rem;
    }
  }
}
.page-list-row {
  margin-bottom: 18px;
}
.page-list-img-edit{
  position: absolute;
  bottom: 0px;
  right: -10px;
}
</style>
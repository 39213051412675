<template>
  <div class="folder-form">
    <el-form :model="ruleForm" label-position="top" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="line">Title</div>
      <el-form-item label="English" prop="title_en">
        <el-input v-model="ruleForm.title_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="title_zhHant">
        <el-input v-model="ruleForm.title_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="title_zhCn">
        <el-input v-model="ruleForm.title_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <div class="line">Thumbnail</div>
      <el-form-item prop="thumbnail_en" label="English">
        <div class="logo">
          <img :src="ruleForm.thumbnail_en" class="image" v-if="ruleForm.thumbnail_en" alt="">
          <div class="image" v-else></div>
          <div class="logo-info">
            <span>Type: PNG, JPG or BMP</span>
            <span>Maximum file size:  5MB</span>
            <span>Dimension: at least 282px(H) x 504px(W)</span>
            <el-button type="primary" @click="isUpdateFile('thumbnail_en')">Browse</el-button>
            <el-button type="danger" @click="removeThumbnail('thumbnail_en')">Delete</el-button>
          </div>
        </div>

      </el-form-item>
      <el-form-item prop="thumbnail_zhHant" label="Traditional Chinese">
        <div class="logo">
          <img :src="ruleForm.thumbnail_zhHant" class="image" v-if="ruleForm.thumbnail_zhHant" alt="">
          <div class="image" v-else></div>
          <div class="logo-info">
            <span>Type: PNG, JPG or BMP</span>
            <span>Maximum file size:  5MB</span>
            <span>Dimension: at least 282px(H) x 504px(W)</span>
            <el-button type="primary" @click="isUpdateFile('thumbnail_zhHant')">Browse</el-button>
            <el-button type="danger" @click="removeThumbnail('thumbnail_zhHant')">Delete</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="thumbnail_zhCn" label="Simplified Chinese">
        <div class="logo">
          <img :src="ruleForm.thumbnail_zhCn" class="image" v-if="ruleForm.thumbnail_zhCn" alt="">
          <div class="image" v-else></div>
          <div class="logo-info">
            <span>Type: PNG, JPG or BMP</span>
            <span>Maximum file size:  5MB</span>
            <span>Dimension: at least 282px(H) x 504px(W)</span>
            <el-button type="primary" @click="isUpdateFile('thumbnail_zhCn')">Browse</el-button>
            <el-button type="danger" @click="removeThumbnail('thumbnail_zhCn')">Delete</el-button>
          </div>
        </div>
      </el-form-item>

      <div class="line">Description</div>
      <el-form-item label="English" prop="pass">
        <el-input v-model="ruleForm.description_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="pass">
        <el-input v-model="ruleForm.description_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="pass">
        <el-input v-model="ruleForm.description_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <div class="line">Subpages</div>
      <div class="pages" v-for="(its, key) in ruleForm.sub_pages" :key="key">
        <div class="text">{{ its.title.en }}</div>
        <div class="operation">
          <img src="@/assets/images/Edit - large.png" alt="" @click="editPage(key)">
          <img src="@/assets/images/mingcute_delete-2-fill.png" alt="" @click="delPage(key)">
        </div>
      </div>

      <div class="add">
        <div class="add-btn" @click="addPage">
          <img src="@/assets/images/add.png" alt="">
          <span>Add</span>
        </div>
      </div>

      <div class="line">Document(s)</div>
      <div v-for="(its, idx) in ruleForm.documents" :key="idx + '-doc'" class="doc">
        <img class="close" src="@/assets/images/close.png" alt="" @click="remove(idx)">

        <el-form-item label="Title - English" :prop="`documents[${idx}].title_en`">
          <el-input v-model="its.title_en" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Title - Traditional Chinese" :prop="`documents[${idx}].title_zhHant`">
          <el-input v-model="its.title_zhHant" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Title - Simplified Chinese" :prop="`documents[${idx}].title_zhCn`">
          <el-input v-model="its.title_zhCn" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Subtitle - English" :prop="`documents[${idx}].subtitle_en`">
          <el-input v-model="its.subtitle_en" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Subtitle - Traditional Chinese" :prop="`documents[${idx}].subtitle_zhHant`">
          <el-input v-model="its.subtitle_zhHant" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Subtitle - Simplified Chinese" :prop="`documents[${idx}].subtitle_zhCn`">
          <el-input v-model="its.subtitle_zhCn" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item>
          <el-radio-group v-model="its.type">
            <el-radio :label="0">PDF</el-radio>
            <el-radio :label="1">External Link</el-radio>
            <el-radio v-model="its.type" :label="2">Photo</el-radio>
            <el-radio v-model="its.type" :label="3">Video</el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="group">
          <el-form-item label="Link - English" :prop="`documents[${idx}].link_en`">
            <edit-div v-model="its.link_en"></edit-div>
          </el-form-item>
          <el-form-item label="Link - Traditional Chinese" :prop="`documents[${idx}].link_zhHant`">
            <edit-div v-model="its.link_zhHant"></edit-div>
          </el-form-item>
          <el-form-item label="Link - Simplified Chinese" :prop="`documents[${idx}].link_zhCn`">
            <edit-div v-model="its.link_zhCn"></edit-div>
          </el-form-item>
        </div>

        <el-form-item label="Sorting Order">
          <el-input v-model.number="its.sorting_order" autocomplete="off"></el-input>
        </el-form-item>
      </div>

      <div class="add">
        <div class="add-btn" @click="add">
          <img src="@/assets/images/add.png" alt="">
          <span>Add</span>
        </div>
      </div>
    </el-form>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { updateFile } from '@/utils/upload'

export default {
  props: {
    form: {
      type: Object
    }
  },
  data() {
    return {
      ruleForm: {},
      subpages: [],
      rules: {
        title_en: [
          { required: true, message: 'Title English can not be empty'}
        ],
        title_zhHant: [
          { required: true, message: 'Title Traditional Chinese can not be empty'}
        ],
        title_zhCn: [
          { required: true, message: 'Title Simplified Chinese can not be empty'}
        ],
        // thumbnail_en: [
        //   { required: true, message: 'Please upload thumbnails in English'}
        // ],
        // thumbnail_zhHant: [
        //   { required: true, message: 'Please upload thumbnails in Traditional Chinese'}
        // ],
        // thumbnail_zhCn: [
        //   { required: true, message: 'Please upload thumbnails in Simplified Chinese'}
        // ],
        'documents[0].title_en': [
          { required: true, message: 'Title English can not be empty', trigger: 'blur'}
        ],
        'documents[0].title_zhHant': [
          { required: true, message: 'Title Traditional Chinese can not be empty', trigger: 'blur'}
        ],
        'documents[0].title_zhCn': [
          { required: true, message: 'Title Simplified Chinese can not be empty', trigger: 'blur'}
        ],
        
        'documents[0].link_en': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
        
        ],
        'documents[0].link_zhHant': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
         
        ],
        'documents[0].link_zhCn': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
         
        ],
      },
      pageId: null,

    }
  },
  computed: {
    ...mapState(['setTempEpisodespage'])
  },
  methods: {
    isUpdateFile(label) {
      let suffix = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/svg+xml'], 
      area = { width: 504, height: 282 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        this.ruleForm[label] = res
      })
    },
    removeThumbnail(label) {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.ruleForm[label] = ''
      })
    },
    add() {
      this.ruleForm.documents.push({
        title_en: '',
        title_zhHant: '',
        title_zhCn: '',
        subtitle_en: '',
        subtitle_zhHant: '',
        subtitle_zhCn: '',
        type: 0,
        link_en: '',
        link_zhHant: '',
        link_zhCn: '',
        sorting_order: 0
      })

      this.verifyLink()
    },
    verifyLink() {
      let leng = this.ruleForm.documents.length
      for(let i = 0; i < leng; i++) {
        this.rules[`documents[${i}].title_en`] = [
          { required: true, message: 'Title English can not be empty', trigger: 'blur'}
        ]
        this.rules[`documents[${i}].title_zhHant`] = [
          { required: true, message: 'Title Traditional Chinese can not be empty', trigger: 'blur'}
        ]
        this.rules[`documents[${i}].title_zhCn`] = [
          { required: true, message: 'Title Simplified Chinese can not be empty', trigger: 'blur'}
        ]
       

        this.rules[`documents[${i}].link_en`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
          
        ]
        this.rules[`documents[${i}].link_zhHant`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
          
        ]
        this.rules[`documents[${i}].link_zhCn`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
          
        ]
      }
    },
    remove(idx) {
      let formArr = this.ruleForm.documents
      formArr.splice(idx, 1)
    },
    addPage() {
      this.$emit('tabPage', 'folder', this.ruleForm)
     
    },
    editPage(idx) {
      this.$emit('tabPage', 'folder', this.ruleForm, idx)
     
    },
    delPage(idx) {
      let subpages = this.ruleForm.sub_pages
      subpages.splice(idx, 1)
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId
    this.ruleForm = JSON.parse(JSON.stringify(this.form))
  }
}
</script>

<style lang="scss" scoped>
.doc {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #F5F5F5;
  margin-bottom: 10px;
  position: relative;

  .close {
    width: 28px;
    height: auto;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.group {
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > * {
    width: 32%;
  }
}

.add {
  width: 100%;
  margin: 30px 0 40px 0;

  .add-btn {
    width: 120px;
    height: 44px;
    border: 1px solid #1A74B0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    img {
      width: 24px;
      height: auto;
      margin-right: 10px;
    }
  }
}
.pages {
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  box-sizing: border-box;
  font-size: 18px;
  color: #000000;
  background-color: #F5F5F5;
  position: relative;

  .text {
    display: flex;
    align-items: center;

    img {
      width: 28px;
      height: auto;
    }

    .arrow-left {
      margin-right: 10px;
    }

    .arrow-right {
      margin-left: 10px;
    }
  }

  .operation {
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: auto;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.logo {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;

  .image {
    width: 284px;
    height: 188px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;
    .icon {
      width: 2.5rem;
      height: auto;
      transform: rotateZ(45deg);
    }
  }

  .logo-info {
    flex: 1;
    padding-left: 1.25rem;
    color: #000;
    font-size: 1.125rem;
    line-height: 1.25rem;

    span {
      display: block;
      margin-bottom: .625rem;
    }
  }
}

</style>
<template>
  <div class="">
    <el-menu class="sidebar" :default-active="defaultActive" :unique-opened="true" :router="true" 
      ref="menu">
      <div class="" v-for="(its, idx) in menus" :key="idx">
        <el-menu-item :index="its.route" v-if="!its.child">{{ its.menuName }}</el-menu-item>
        <el-submenu v-else :index="idx + 'a'">
          <template slot="title">
            <span>{{ its.menuName }}</span>
          </template>
          <div class="" v-for="(item, index) in its.child" :key="index + 'a'">
            <el-submenu :index="idx + 'a-' + index" class="child-submenu" v-if="item.child">
              <template slot="title">{{ item.menuName }}</template>

              <div class="" v-for="(items, indexs) in item.child" :key="indexs + 'b'">
                <el-submenu :index="idx + 'a-' + index + 'a-' + indexs" v-if="items.child">
                  <template slot="title">
                    <span>{{ items.menuName }}</span>
                  </template>
                  <el-menu-item :index="itemsc.route"
                  v-for="(itemsc, indexsc) in items.child" :key="indexsc">{{ itemsc.menuName }}</el-menu-item>
                </el-submenu>

                <el-menu-item :index="items.route" v-else>{{ items.menuName }}</el-menu-item>
              </div>

            </el-submenu>
            <el-menu-item :index="item.route" v-else>{{ item.menuName }}</el-menu-item>
          </div>
        </el-submenu>
        
      </div>
    </el-menu>
    
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      roleName: '',
      shopManager: '',
      roleSuperAdmin: '',
      menus: [
        {menuName: 'Overview', route: '/overview', child: null},
        {menuName: 'First Row Sections', route: '/frs', child: [
          {menuName: 'Work Safety Alert', route: '/wsa', child: [
            {menuName: 'Text Version', route: '/firstRowSections/textVersion', template: 0,},
            {menuName: 'Animation', route: '/firstRowSections/animation', template: -2},
          ]},
          {menuName: 'Important Announcement', route: '/firstRowSections/ImportAnnoun', template: 4,},
        ]},
        {menuName: 'Second Row Sections', route: '/srs', child: [
          {menuName: 'Systemic Safety Alert', route: '/template/ssa/systemicSafetyAlert', template: 1,},
          {menuName: 'Catering Safety Animation', route: '/secondRowSections/cateringSafetyAnimation', template: 2,},
          {menuName: 'OSH Training', route: '/secondRowSections/oshTraining', template: 3,},
          {menuName: 'Notify Your Workplace', route: '/secondRowSections/notifyWorkplace', template: 5,},
        ]},
      ], 

      defaultActive: '',
      roleSections: []
    }
  },
  computed: {
    ...mapState(['Pages', 'Section']),
    adminUserInfo: function() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo')) 
      return userInfo
    },
  },
  watch: {
    $route(to) {
      this.defaultActive = decodeURI(to.fullPath)
      this.$nextTick(() => {
        this.$refs.menu.activeIndex = this.defaultActive
      })
    },
    Section: function(v) {
      let data = JSON.parse(JSON.stringify(v)),
      sections = [] , seconds = [], 
      type = ['textVersion', 'animation', 'ssa', 'csa', 'osh', 'ia', 'NotifyYourWorkplace', '', 'episodes']
      // data.filter(its => its.displayType == 0 ? sections.push(its) : seconds.push(its))
      data = data.filter((its) => {
        if(its.displayType == 1) {
          its.sort += 4
        } else {
          sections.push(its)
        }
        return its
      }).sort((a, b) => a.sort - b.sort)
      data.filter((its, idx) => {
        if(idx < (4 - sections.length)) {
          sections.push(its)
        } else if(its.displayType == 1) {
          seconds.push(its)
        }
      })

      // first
      let menus = JSON.parse(JSON.stringify(this.menus))
      menus[1].child = []
      sections.filter(its => its.pages.length && its)
      .filter(its => {
        let { pages } = its,
        menu = {menuName: its.title.en, route: '/404'}
        if(pages.length == 2) {
          let [text, animation] = pages
          menu = {menuName: its.title.en, route: '/wsa', child: [
              {menuName: 'Text Version', route: `/template/wsa/textVersion/${text.id}?sectionName=${its.title.en}`},
              {menuName: 'Animation', route: `/template/wsa/animation/${animation.id}?sectionName=${its.title.en}`},
            ]}
        } else {
          let [page] = pages
          menu.route = `/template/${type[page.type]}/${page.id}?sectionName=${its.title.en}`
        }
        menus[1].child.push(menu)
      })

      // second
      menus[2].child = []
      seconds.filter(its => its.pages.length && its)
      .filter((its) => {
        let { pages } = its,
        menu = {menuName: its.title.en, route: '/404'}
        if(pages.length == 2) {
          let [text, animation] = pages
          menu = {menuName: its.title.en, route: '/wsa', child: [
              {menuName: 'Text Version', route: `/template/wsa/textVersion/${text.id}?sectionName=${its.title.en}`},
              {menuName: 'Animation', route: `/template/wsa/animation/${animation.id}?sectionName=${its.title.en}`},
            ]}
        } else {
          let [page] = pages
          menu.route = `/template/${type[page.type] || page.type}/${page.id}?sectionName=${its.title.en}`
        }
        menus[2].child.push(menu)
      })

      this.menus[1].child = []
      this.menus[2].child = []
      setTimeout(() => {
        this.menus = JSON.parse(JSON.stringify(menus))
        console.log(this.menus)
      }, 500)
    }
  },
  methods: {
    ...mapMutations(['setSection']),
    menusInsertPages(arr) {
      for(let i = 0; i < arr.length; i++) {
        let item = arr[i]
        if(item.child) this.menusInsertPages(item.child)
        else if(item.template != undefined) {
          item.child = this.Pages.map(its => {
            if(its.templateType == item.template) {
              return {menuName: its.path, route: `${item.route}/${its.id}`}
            }
            return false
          }).filter(its => its && its)
          item.child = item.child[0] ? item.child : null
        }
      }
    },
  },
  mounted() {
    if(this.adminUserInfo.position == 1) {
      this.menus.push(...[
        {menuName: 'Others', route: '/rt', child: [
          {menuName: 'What’s New', route: '/new', child: null},
          {menuName: 'About Us', route: '/aboutUs', child: null},
          {menuName: 'Notices', route: '/notice', child: [
            {menuName: 'Disclaimer', route: '/notices/0', child: null},
            {menuName: 'Copyright Notice', route: '/notices/1', child: null},
            {menuName: 'Privacy Policy', route: '/notices/2', child: null},
            {menuName: 'Accessibility Statement', route: '/notices/3', child: null},
          ]},
        ]},
        {menuName: 'interstitial Ads', route: '/interstitialAds', child: null},
        {menuName: 'Report', route: '/report', child: [
          {menuName: 'Edit History', route: '/editHistory', child: null},
          {menuName: 'Link Check', route: '/linkCheck', child: null},
        ]},
        {menuName: 'Setting', route: '/setting', child: [
          {menuName: 'Account', route: '/account', child: null},
          {menuName: 'Admin Permission', route: '/adminPermission', child: null},
          {menuName: 'Share Message', route: '/shareMessage', child: null},
        ]},
      ])
    } else {
      this.menus.push(...[
        {menuName: 'Setting', route: '/setting', child: [
          {menuName: 'Account', route: '/account', child: null},
        ]},
      ])
    }
    this.getSection()
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  min-height: 159vh;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
}
.el-menu{
  background-color: #fff !important ;
  border: none;
}
.el-menu-item{
  background-color: #1A74B0 !important;
  border-bottom: 1px solid #fff;
  color: #fff !important;
  font-size: 18px;
  font-weight: 700;
  //css 英文强制换行
  word-wrap:break-word;
  white-space:pre-wrap;
}

::v-deep{

.el-submenu__title{
  background-color: #1A74B0 !important;
  border-bottom: 1px solid #fff;
  color: #fff !important;
  font-size: 18px;
}
.el-menu--inline .el-submenu__title{
  background-color: #E0F3FF !important;
  border-bottom: 1px solid #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: #000 !important;
}
.el-menu--inline .el-menu-item{
  background-color: #E0F3FF !important;
  border-bottom: 1px solid #fff;
  font-size: 18px;
  font-style: normal;
  color: #000 !important;
}
.el-submenu .el-menu-item {
  height: auto;
}
.child-submenu .el-menu-item{
  background-color: #E0F3FF !important;
  border-bottom: 1px solid #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: #000 !important;
}
.el-menu--inline .el-menu-item.is-active{
  background-color: #BCDCF1 !important;
}
.el-icon-arrow-down:before{
  color: #fff;
  font-weight: 800;
}
.el-submenu  .el-icon-arrow-down:before{
    content: "\e6d9";
}
.el-submenu.is-opened  .el-icon-arrow-down:before{
    content: "\e6d8";
}

.child-submenu .el-icon-arrow-down:before{
  color: #000;
  font-weight: 800;
}
.child-submenu  .el-icon-arrow-down:before{
    content: "\e6d9" !important;
}
.child-submenu.is-opened  .el-icon-arrow-down:before{
    content: "\e6d8" !important;
}
}

.is-active {
  background-color: #005C99 !important;
}

</style>

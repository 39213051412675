<template>
  <div class="edit">
    <Header-Back pageTitle="Edit - Banners"></Header-Back>
    
    <div class="banner-list">
      <div class="single" v-for="(its, idx) in banners" :key="idx">
        <div class="single-title">Banner {{ idx + 1 }}</div>

        <div class="container">
          <div class="config">
            <div class="trilingual">
              <div class="item">
                <div class="label">English</div>
                <div class="img-area-img" v-if="its.imageUrl.en">
                  <img :src="its.imageUrl.en" alt="">
                </div>
                <div class="img-area" v-else></div>
                
                <div class="btn" @click="updateImg(its.imageUrl, 'en')">Browse</div>
              </div>
              <div class="item">
                <div class="label">Traditional</div>
                <div class="img-area-img" v-if="its.imageUrl.zhHant">
                  <img :src="its.imageUrl.zhHant" alt="">
                </div>
                <div class="img-area" v-else></div>

                <div class="btn" @click="updateImg(its.imageUrl, 'zhHant')">Browse</div>
              </div>
              <div class="item">
                <div class="label">Simplified</div>
                <div class="img-area-img" v-if="its.imageUrl.zhCn">
                  <img :src="its.imageUrl.zhCn" alt="">
                </div>
                <div class="img-area" v-else></div>
                
                <div class="btn" @click="updateImg(its.imageUrl, 'zhCn')">Browse</div>
              </div>
            </div>
            <div class="image-standard">
              <span>Type: PNG, JPG or BMP</span>
              <span>Maximum file size:  5MB</span>
              <span>Dimension: at least 600px(H) x 1170px(W)</span>
            </div>

            <div class="func">
              <div class="navigate">
                <div class="label">Navigate to</div>

                <div class="value">
                  <el-select v-model="its.pages" multiple :disabled="its.en != '' || its.zhHant != '' || its.zhCn != ''"
                  placeholder="- Internel page -" class="select" @change="v => changeTemplate(its, v)">
                    <el-option v-for="(item, idx) in its.tempPages" :key="idx"
                      :label="item.path" :value="item.id" :disabled="item.disable">
                    </el-option>
                  </el-select>
                  
                  <el-input v-model="its.en" :disabled="its.pages.length != 0" class="select" placeholder="Link - English"></el-input>
                  <el-input v-model="its.zhHant" :disabled="its.pages.length != 0" class="select" placeholder="Link - Traditional Chinese"></el-input>
                  <el-input v-model="its.zhCn" :disabled="its.pages.length != 0" class="select" placeholder="Link - Simplified Chinese"></el-input>
                </div>
              </div>

              <div class="navigate">
                <div class="label">Alt text</div>

                <div class="value">
                  <el-form :model="its" :rules="rules" :ref="`AltText` + idx">
                    <el-form-item prop="altText.en">
                      <el-input v-model="its.altText.en" class="select" placeholder="Alt text - English"></el-input>
                    </el-form-item>
                    <el-form-item prop="altText.zhHant">
                      <el-input v-model="its.altText.zhHant" class="select" placeholder="Alt text - Traditional Chinese"></el-input>
                    </el-form-item>
                    <el-form-item prop="altText.zhCn">
                      <el-input v-model="its.altText.zhCn" class="select" placeholder="Alt text - Simplified Chinese"></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
          <div class="setting">
            <div class="order">
              <img src="@/assets/images/order_up.png" alt="" @click="order('up', idx)">
              <img src="@/assets/images/order_down.png" alt="" @click="order('down', idx)">
            </div>

            <div class="delete" @click="del(idx)">Delete</div>
          </div>
        </div>
      </div>
    </div>

    <div class="add">
      <div class="add-btn" @click="add">
        <img src="@/assets/images/add.png" alt="">
        <span>Add</span>
      </div>
    </div>

    <div class="save">
      <div class="save-btn" @click="save">
        Save
      </div>
    </div>

  </div>
</template>

<script>
import { updateFile } from '@/utils/upload'
import { getBanners, editBanners } from '@/api/banner.js'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      banners: [],

      rules: {
        'altText.en': [
          { required: true, message: 'Please enter `Alt text - English`', trigger: 'blur' },
        ],
        'altText.zhHant': [
          { required: true, message: 'Please enter `Alt text - Traditional Chinese`', trigger: 'blur' },
        ],
        'altText.zhCn': [
          { required: true, message: 'Please enter `Alt text - Simplified Chinese`', trigger: 'blur' },
        ],
      }
    }
  },
  computed: {
    ...mapState(['Pages'])
  },
  methods: {
    updateImg(item, key) {
      let suffix = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/svg+xml'], 
      area = { width: 1170, height: 600 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        item[key] = res
      })
    },
    updateTempPages(testData) {
      let pages = JSON.parse(JSON.stringify(this.Pages)).map(its => ({...its, disable: false})), 
      [id] = testData

      if(id == undefined) return pages
      let [filterChoice] = pages.filter(its => its.id == id && its)
      if(filterChoice && filterChoice.type == 0) {
        pages.map(its => {
          if(its.id != id && its.id != (id + 1))
            its.disable = true
        })
      } else if(filterChoice.type == 1) {
        pages.map(its => {
          if(its.id != id && its.id != (id - 1))
            its.disable = true
        })
      } else {
        pages.map(its => its.id != id && (its.disable = true))
      }
      
      return pages
    },
    changeTemplate(its, v) {
      its.tempPages = this.updateTempPages(v)
    },
    add() {
      this.banners.push({
        imageUrl: {
          en: '',
          zhHant: '',
          zhCn: '',
        },
        altText: {
          en: '',
          zhHant: '',
          zhCn: '',
        },
        en: '',
        zhHant: '',
        zhCn: '',
        pages: [],
        tempPages: this.updateTempPages([])
      })
    },

    del(idx) {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.banners.splice(idx, 1)
      })
    },

    order(type, idx) {
      let { banners } = this,
      leng = banners.length
      if(type == 'up' && idx != 0) {
        [banners[idx], banners[idx - 1]] = [banners[idx - 1], banners[idx]];
      } else if(type == 'down' && idx != leng - 1) {
        [banners[idx], banners[idx + 1]] = [banners[idx + 1], banners[idx]];
      }

      this.banners = JSON.parse(JSON.stringify(banners))
    },
    getData() {
      getBanners().then(v => {
        v.data.filter(its => {
          its.pages = its.pages.map(i => i.id)
          its.tempPages = this.updateTempPages(its.pages)
          its.en = its.link.en
          its.zhHant = its.link.zhHant
          its.zhCn = its.link.zhCn
        })
        this.banners = v.data
      })
    },

    save() {
      let { banners } = this,
      data = banners.map(its => {
        return {
          "page_ids": its.pages,
          "link": {
            "en": its.en,
            "zh_hant": its.zhHant,
            "zh_cn": its.zhCn
          },
          "alt_text": {
            "en": its.altText.en,
            "zh_hant": its.altText.zhHant,
            "zh_cn": its.altText.zhCn,
          },
          "image_url": {
            "en": its.imageUrl.en,
            "zh_hant": its.imageUrl.zhHant,
            "zh_cn": its.imageUrl.zhCn
          }
        }
      })
      let isVerify = true
      for(let i = 0; i < this.banners.length; i++) {
        let [alyTexts] = this.$refs[`AltText` + i]
        console.log(alyTexts)
        alyTexts.validate((valid) => {
          if (!valid) {
            isVerify = false
            this.$message.error('An unfilled field exists')
          }
        });
      }

      if(isVerify) {
        editBanners(data).then(() => {
          this.$router.go(-1)
        })
      }
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.banner-list {
  width: 100%;
  margin-bottom: 40px;

  .single {
    width: 100%;
    margin-bottom: 20px;

    .single-title {
      width: 100%;
      font-size: 18px;
      color: #000;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .container {
      width: 100%;
      height: fit-content;
      padding: 20px;
      box-sizing: border-box;
      background-color: #F5F5F5;
      display: flex;

      .config {
        width: calc(100% - 150px);
        min-height: 200px;

        .trilingual {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;

          .item {
            width: fit-content;
            height: 100%;
            margin-right: 20px;

            .img-area {
              width: 390px;
              height: 200px;
              border: 1px dashed #6D6D6D;
              margin: 10px 0;
            }

            .img-area-img {
              width: 390px;
              height: 200px;
              border: 1px solid #6D6D6D;
              margin: 10px 0;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .btn {
              width: 114px;
              height: 44px;
              background-color: #1A74B0;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              font-weight: 700;
              color: #fff;
              cursor: pointer;
            }
          }
        }

        .image-standard {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          color: #000;
          font-size: 18px;
          margin-bottom: 30px;

          & > * {
            width: 100%;
          }
        }

        .func {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-content: flex-start;
          
          .navigate {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 30px;
  
            .label {
              padding: 10px 0;
            }
  
            .value {
              width: 600px;
            }
  
            .select {
              width: 500px;
              margin: 0 10px 10px 0;
            }
          }
        }

      }

      .setting {
        width: 150px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-between;

        .order {
          width: 100%;
          padding-left: 30px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            width: 48px;
            height: auto;
            cursor: pointer;
          }
        }
        
        .delete {
          width: 106px;
          height: 44px;
          background-color: #D53E19;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }

    .label {
      color: #000;
      font-size: 18px;
      font-weight: 700;
      margin-right: 10px;
      white-space: nowrap;
    }
  }

  .single:nth-last-of-type(1) {
    margin-bottom: 0;
  }
}

.add {
  width: 100%;
  margin-bottom: 40px;

  .add-btn {
    width: 120px;
    height: 44px;
    border: 1px solid #1A74B0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    img {
      width: 24px;
      height: auto;
      margin-right: 10px;
    }
  }
}

.save {
  width: 100%;

  .save-btn {
    width: 220px;
    height: 44px;
    background-color: #124B80;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
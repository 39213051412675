<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getPages } from '@/api/pages'
import { getTemplates } from '@/api/template.js'
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {

    }
  },
  watch: {
    $route: function(to, form) {
      if(form.path == '/login' && to.path == '/overview') {
        this.getPages()
        this.getTemplate()
      }
    }
  },
  methods: {
    ...mapMutations(['setPages', 'setTemplate']),
    getPages(){
      getPages().then(res => {
        this.setPages(res.data)
      });
    },
    getTemplate() {
      getTemplates().then(v => {
        this.setTemplate(v.data)
      })
    }
  },
  created() {
    if(getToken()){
      this.getPages()
      this.getTemplate()
    }
    
    if(!this.$store.getters.adminUserInfo.id) {
      this.$store.dispatch("setAdminUserInfo", JSON.parse(localStorage.getItem('userInfo')));
    }
  }
}
</script>

<style>
html,body,#app{
 margin: 0px;
  height: 100% !important;
  -webkit-print-color-adjust: exact;
}

</style>